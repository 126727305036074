<script>
/* eslint-disable */
export default {
  name: 'FourOFour',
};
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 1080 1080" style="enable-background:new 0 0 1080 1080;" xml:space="preserve">
  <g>
	<circle class="st0" cx="861.7" cy="233" r="123.7"/>
</g>
    <g>
	<path class="st1" d="M346,246.4c-23.9,10.4-47.6,23.2-64.3,43.2c-16.7,20-25.1,48.7-15,72.8c13.4,32.1,55.8,50.8,56,85.6   c0.1,24.9-22.9,43.9-46.1,53c-23.2,9.1-48.9,12.3-70,25.6c-31.9,20-46.8,58.7-52.9,95.8c-6.3,38.5-3.8,82.4,23.5,110.4   C211,767.3,267,763.4,315,756c64-9.9,127.7-21.5,191.5-33.1c139.7-25.5,279.4-50.9,419-76.4c15-2.7,30.6-5.7,42.7-14.9   c24.2-18.2,26.2-55.7,12.2-82.4c-14-26.8-40-45-65.8-60.7s-53.1-30.6-72.4-53.9c-19.3-23.2-28.9-57.7-14.3-84.1   c12.4-22.4,39-34.4,50.6-57.2c15.3-30.1-2.3-68.5-30.1-87.7C820.6,186.4,472.1,191.2,346,246.4z"/>
</g>
    <g>
	<rect x="332.7" y="694.4" class="st2" width="242.5" height="188.4"/>
      <path class="st3" d="M222,882.9h463.9c22.6,0,40.9,18.3,40.9,40.9l0,0H181.1l0,0C181.1,901.2,199.4,882.9,222,882.9z"/>
      <g>
		<path class="st3" d="M775,773H132.9c-10.9,0-19.7-8.8-19.7-19.7V378.4c0-10.9,8.8-19.7,19.7-19.7H775c10.9,0,19.7,8.8,19.7,19.7    v374.8C794.7,764.1,785.9,773,775,773z"/>
        <path class="st4" d="M762.5,748.9H145.4c-8.4,0-15.3-6.8-15.3-15.3V388.8c0-8.4,6.8-15.3,15.3-15.3h617.2    c8.4,0,15.3,6.8,15.3,15.3v344.8C777.8,742.1,771,748.9,762.5,748.9z"/>
	</g>
</g>
    <path class="st5" d="M57.9,918.9h964.2c6.6,0,12,5.4,12,12v55.6c0,6.6-5.4,12-12,12H57.9c-6.6,0-12-5.4-12-12v-55.6  C45.9,924.3,51.3,918.9,57.9,918.9z"/>
    <g>
	    <path class="st6" d="M927,904.3c-6.2,8.3-18,32.6-36.4,34c16.1,2.7,38.1-0.7,41.6-9.2c2.3,6.8,0.9,8.7,0.9,8.7l11.8-1.1l-0.3-32.9   L927,904.3z"/>
      <path class="st6" d="M840,903.8c-6.8,7.8-19.5,32.5-37.9,32.5c15.8,3.9,38,2.2,42.1-6c1.8,7,0.2,8.7,0.2,8.7l11.8-0.2l2.2-32.8   L840,903.8z"/>
      <path class="st7" d="M884.9,628c0,0-62.4,260.3-72.4,271.7c14.1,3.5,70.3,15,69.8,6.9c0,0,9.8-49.2,3.1-131.9   c7.1-52.7,56.8-97.7,23.8-147.7C901.5,622.4,884.9,628,884.9,628z"/>
      <path class="st8" d="M884.9,628l5.1,21.8c0,0,9.5,252.9,2.9,266.6c14.5-0.4,71.7-4.3,69.1-12c0,0-3.8-50-32.2-127.9   c-7.2-52.7,33.4-110.1-11.8-149.4C887.4,616.4,884.9,628,884.9,628z"/>
      <path class="st9" d="M887.5,550c-19.1,10.5-40.8,100.7-59.5,17.2c-6.6,0.4-10.1,0.3-10.1,0.3s4.3,93.6,73.6,15.2   C899.3,540,887.5,550,887.5,550z"/>
      <path class="st2" d="M893.2,547.9l-5.7,2c0,0-16.8,31-18.4,35.8c2,7.5,15.2,28,15.9,42.4c9.1,3,23.5,3.7,33.1-0.9   c1-2.9,7.1-42,10.5-63.5c1.1-7.3-4.4-14-11.8-14.2l-6.8-0.2L893.2,547.9z"/>
    <g>
		    <path class="st10" d="M881.6,518.8c-6-5-11.1-19.1-3-23.2c7.2-3.6,32.7-8.8,38.2,10.1c1.4,4.7,1,9.7-0.7,14.3    c-4.6,12.7-14.4,21-14.4,21L881.6,518.8z"/>
        <path class="st11" d="M896.6,534.4c-0.2,2-0.9,11.7-3.4,13.5c3.2,6,4.1,11.8,4.1,11.8s9.9-9.1,12.5-10.5    c-1.5-5.5-6.7-19.1-6.7-19.1L896.6,534.4z"/>
        <path class="st12" d="M881.1,504.1c-1.5,8.6-2.7,25.8,2.4,33.8c2.1,3.3,5.9,5.1,9.8,4.8c12.2-1,13.5-9.1,15.2-17    C910.4,517.1,908.7,486.1,881.1,504.1z"/>
      <g>
			<path class="st10" d="M877,503c4.6,7.9,11.8,13.2,19.3,14.1c3.2,0.4,6.5,0.1,9.8,0.8c3.2,0.7,6.5,2.7,8.1,6.4     C914.2,524.4,904.5,478.6,877,503z"/>
		</g>

        <ellipse transform="matrix(0.2576 -0.9662 0.9662 0.2576 167.3898 1269.4724)" class="st12" cx="909.8" cy="525.8" rx="5.1" ry="3.2"/>
        <g>
			<path class="st10" d="M902.3,498c3.4-4.8,6.9-9.7,11.6-13.1c4.8-3.4,11.1-5.2,16.6-3.2c3.4,1.3,6.2,3.9,9.4,5.7     c7.8,4.2,17.4,2.9,25.7-0.1c8.3-3,16.1-7.5,24.6-9.7c8.5-2.2,18.6-1.5,24.9,4.7c8.9,8.8,5.3,25.3-4.8,32.6     c-10.2,7.3-24.2,6.9-36,2.6c-13.6-4.9-27.1-14.5-41.1-11.2c-4.3,1-8.2,3.2-12.2,5c-4,1.8-8.4,3.2-12.8,2.6     c-4.4-0.6-8.7-3.7-9.3-8.1c-0.6-4.4,3.8-9.1,8-7.7"/>
		</g>
        <path class="st13" d="M906,536.2c0,3.6,1.4,6.5,3,6.5c1.7,0,3-2.9,3-6.5c0-3.6-3-6.5-3-6.5S906,532.6,906,536.2z"/>
	</g>
      <path id="Shape" class="st13" d="M1053.6,727.1c-0.2,2.3-1.6,4.2-3.8,5c-2.1,0.8-4.5,0.2-6.1-1.4l-24.5-24.5   c-1.7-1.7-4.2-2.2-6.4-1.2l-22.7,9.7c-0.8,0.4-1.5,0.8-2,1.4c-0.4,0.5-0.7,1-1,1.6l-9.9,23c-1,2.2-0.5,4.7,1.2,6.4l24.6,24.5   c1.6,1.6,2.1,4,1.3,6.1c-0.8,2.1-2.7,3.6-5,3.7c-18.5,1.1-36.3-6.8-48-21.1c-11.7-14.3-15.8-33.4-11-51.3c1-4-0.1-8.2-2.9-11.2   l-56.1-56.1L845.6,606L789.6,550c-3-2.9-7.2-4-11.2-2.9c-17.9,4.8-37,0.7-51.3-11c-14.3-11.7-22.2-29.5-21.1-48   c0.2-2.3,1.6-4.2,3.7-5c2.1-0.8,4.5-0.3,6.1,1.3l24.5,24.6c1.7,1.7,4.2,2.2,6.4,1.2l23-9.9c0.6-0.3,1.1-0.6,1.6-1   c0.6-0.6,1.1-1.3,1.4-2l9.7-22.7c1-2.2,0.5-4.7-1.2-6.4l-24.5-24.5c-1.6-1.6-2.1-4-1.4-6.1s2.7-3.6,5-3.8   c18.5-1.1,36.4,6.8,48,21.1s15.8,33.5,10.9,51.3c-1,4,0.1,8.2,2.9,11.2l65.4,65.4l82.4,82.4c3,2.9,7.2,4,11.2,2.9   c17.9-4.8,37-0.7,51.3,10.9C1046.8,690.8,1054.7,708.7,1053.6,727.1z"/>
      <path id="Shape_1_" class="st14" d="M999.3,781.4c-18.5,1.1-36.3-6.8-48-21.1c-11.7-14.3-15.8-33.4-11-51.3c1-4-0.1-8.2-2.9-11.2   l-56.1-56.1L845.6,606L789.6,550c-3-2.9-7.2-4-11.2-2.9c-17.9,4.8-37,0.7-51.3-11c-14.3-11.7-22.2-29.5-21.1-48   c0.2-2.3,1.6-4.2,3.7-5c2.1-0.8,4.5-0.3,6.1,1.3l24.5,24.6c1.7,1.7,4.2,2.2,6.4,1.2l23-9.9c0.6-0.3,1.1-0.6,1.6-1L988,716.1   c-0.4,0.5-0.7,1-1,1.6l-9.9,23c-1,2.2-0.5,4.7,1.2,6.4l24.6,24.5c1.6,1.6,2.1,4,1.3,6.1C1003.5,779.8,1001.6,781.2,999.3,781.4z"/>
      <path class="st11" d="M817.9,570.9c0,0-4.3,7.6-3.7,9.1s6.7,6.6,6.7,6.6l3.5-5.1L817.9,570.9z"/>
      <path class="st11" d="M921,639.9c0,0-5.5-0.1-5.9-0.8c-0.4-0.7-4.3-6.1-8.7-6.5c0.8,1.2,2.5,5.5,3.8,5.9c-4.8,0-14.7,1.3-16.2,2.2   c1.1,3.4,5.8,8.4,10.2,9.7c6.2-0.3,11.4-3.8,11.4-3.8s5.8,0.1,5.6-0.2C921,646.1,921,639.9,921,639.9z"/>
      <path class="st2" d="M919.4,549.8c37.8,8.2,67.9,104-2.4,101.7c-0.5-3.2,1.6-13.1,1.6-13.1s50.6,6.3,7.6-60.2   C915,569.4,919.4,549.8,919.4,549.8z"/>
</g>
    <g>
	<g>
		<path class="st2" d="M538.9,576.6c-1.4-1.4-3-2.1-4.9-2.1h-24.4v-32l18.8-18.8c1.4-1.4,2.1-3,2.1-4.9c0-1.9-0.7-3.5-2.1-4.9    c-1.4-1.4-3-2.1-4.9-2.1s-3.5,0.7-4.9,2.1l-18.8,18.8H408L389.2,514c-1.4-1.4-3-2.1-4.9-2.1c-1.9,0-3.5,0.7-4.9,2.1    c-1.4,1.4-2.1,3-2.1,4.9c0,1.9,0.7,3.5,2.1,4.9l18.8,18.8v32h-24.4c-1.9,0-3.5,0.7-4.9,2.1c-1.4,1.4-2.1,3-2.1,4.9    c0,1.9,0.7,3.5,2.1,4.9c1.4,1.4,3,2.1,4.9,2.1h24.4c0,11.6,2.1,21.5,6.3,29.8l-22,24.7c-1.2,1.5-1.8,3.1-1.7,5.1    c0.1,1.9,0.9,3.5,2.2,4.8c1.4,1.2,2.9,1.7,4.7,1.7c2,0,3.8-0.8,5.2-2.3l19.9-22.5l1.6,1.5c1,0.9,2.6,2.1,4.7,3.5    c2.1,1.4,4.5,2.8,7.2,4.2c2.6,1.4,5.8,2.6,9.5,3.6c3.7,1,7.3,1.5,11,1.5v-97.5h13.9v97.5c3.5,0,7-0.5,10.6-1.4    c3.6-0.9,6.5-2,8.9-3.2c2.4-1.2,4.8-2.5,7.1-4c2.3-1.5,3.8-2.5,4.6-3.1c0.7-0.6,1.3-1.1,1.6-1.4l21.5,21.4    c1.3,1.4,2.9,2.1,4.9,2.1c2,0,3.6-0.7,4.9-2.1c1.4-1.4,2.1-3,2.1-4.9c0-1.9-0.7-3.5-2.1-4.9L502.4,620c4.9-8.6,7.3-19.1,7.3-31.6    H534c1.9,0,3.5-0.7,4.9-2.1c1.4-1.4,2.1-3,2.1-4.9C541,579.6,540.3,578,538.9,576.6z"/>
    <path class="st2" d="M478.6,494.2c-6.8-6.8-15-10.2-24.6-10.2c-9.6,0-17.9,3.4-24.6,10.2c-6.8,6.8-10.2,15-10.2,24.6h69.6    C488.8,509.2,485.4,501,478.6,494.2z"/>
	</g>
</g>
    <g>
	<path class="st15" d="M148.7,935.8c23.2,0,32.9-18.5,36.8-38.1c4.3-22.1,1.2-45.7,1.2-45.7h-75.9c0,0-0.1,0.9-0.3,2.5   C109,868.4,104.4,935.8,148.7,935.8z"/>
      <path class="st16" d="M110.7,851.9c0,1.7,17,3,37.9,3c21,0,37.9-1.3,37.9-3c0-1.7-17-3-37.9-3C127.7,848.9,110.7,850.3,110.7,851.9   z"/>
      <path class="st2" d="M45.9,641.6c0,0,40,60.9,64,98s30.3,44.9,33.2,56.9c2.9,12,5.4,56.3,5.4,56.3h1.8c0,0-0.6-37.6-2.8-50.3   c-2.1-12.2,0.1-16.6,1.9-29.5c1.7-12.9,4-33.4-9-47.7c-13-14.3-9.6-28.3-14.1-37.7c-4.6-9.4-40-20.9-44.6-30.6   C77.1,647.3,57,641.3,45.9,641.6z"/>
      <path class="st17" d="M45.9,641.6c0,0,12.6,14.3,21.2,19.2c8.6,4.9,12.6,0.6,19.2,12.3c6.6,11.7,6.8,16.9,12.8,22.9   c6,6,13.2,6.3,13.7,19.4c0.6,13.1-2.9,24.3-2.9,24.3S48.5,644.7,45.9,641.6z"/>
      <path class="st2" d="M145.4,852.8h-1.8c0,0-0.5-12.2-5.4-18.1c-3.1-3.8-16.4-8.1-29.5-13.3c-7.2-2.9-14.4-6-19.8-9.4   c-15.2-9.7-29.7-40.8-31-46.4c-0.4-1.9-0.6-3.1-0.6-3.9v0c0-1.5,0.8-1.7,2.1-3.2c0.6-0.7,2.5-0.5,4.7,0.2c6.6,2,12.1,6.5,15.5,12.4   c3.2,5.5,8.3,12.4,14.5,14c10.7,2.9,9.9,4.7,13.8,15.6c4,10.9,13.9,12.7,26.5,25.6C147,839.2,145.4,852.8,145.4,852.8z"/>
      <path class="st17" d="M108.7,821.4c-7.2-2.9-14.4-6-19.8-9.4c-15.2-9.7-29.7-40.8-31-46.4c-0.4-1.9-0.6-3.1-0.6-3.9   c0,0.4,0.8,5.3,8.7,8.6c8.2,3.4,9.8,3.4,10.9,8.6c1.1,5.2,2,10.7,6.3,11.8c4.3,1.1,7-3.8,10.4,4.1c3.4,7.9,5,10.6,8.2,11.6   c3.1,1.1,5.5-0.5,6.2,5.6C108.4,815.7,108.6,819.2,108.7,821.4z"/>
      <path class="st2" d="M158.5,852.8h2.1c0,0,4.2-75.4,9.9-82.4c3.7-4.5,15.9-12.6,31.4-18.8c8.5-3.4,17-7.1,23.4-11.1   c18-11.5,35.2-48.4,36.7-54.9c0.5-2.3,0.8-3.7,0.7-4.6v0c0-1.8-0.9-2-2.4-3.8c-0.7-0.9-2.9-0.6-5.6,0.3   c-7.8,2.4-14.3,7.7-18.4,14.7c-3.8,6.5-9.8,14.6-17.1,16.6c-12.7,3.4-11.7,5.5-16.4,18.5c-4.7,12.9-16.5,15.1-31.4,30.3   C156.6,772.7,158.5,852.8,158.5,852.8z"/>
      <path class="st17" d="M201.9,751.7c8.5-3.4,17-7.1,23.4-11.1c18-11.5,35.2-48.4,36.7-54.9c0.5-2.3,0.8-3.7,0.7-4.6   c-0.1,0.4-0.9,6.3-10.3,10.2c-9.7,4-11.7,4-12.9,10.2c-1.3,6.1-2.3,12.7-7.4,14c-5.1,1.3-8.3-4.5-12.3,4.9c-4,9.3-6,12.5-9.7,13.8   c-3.7,1.3-6.5-0.6-7.3,6.6C202.3,744.9,202,749.1,201.9,751.7z"/>
      <g>
		<path class="st3" d="M148.7,935.8c23.2,0,32.9-18.5,36.8-38.1c-22.7-8.6-51.3-22.4-75-43.2C109,868.4,104.4,935.8,148.7,935.8z"/>
	</g>
</g>
</svg>
</template>

<style scoped>
.st0{fill:none;stroke:#D3D3D3;stroke-miterlimit:10;stroke-dasharray:12.1401,12.1401;}
.st1{fill:#F7F7F7;}
.st2{fill:#2A94F4;}
.st3{fill:#CCE9FF;}
.st4{fill:#FFFFFF;}
.st5{fill:#F2F2F2;}
.st6{fill:#072938;}
.st7{fill:#072C3D;}
.st8{fill:#0A3851;}
.st9{fill:#1D8CDD;}
.st10{fill:#0B4870;}
.st11{fill:#FCD2B1;}
.st12{fill:#FFE3CA;}
.st13{fill:#FFBE55;}
.st14{fill:#F9AC3D;}
.st15{fill:#B6E7FF;}
.st16{fill:#85DDFF;}
.st17{fill:#41AAF7;}
</style>
